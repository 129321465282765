
import { defineComponent, PropType } from 'vue'
import { DspOverwritingItem, DspOverwritingValue } from '../../../../../../types/surcouche_v2_types'
import LabelLine from '@/components/TableComponents/Tools/LabelLine.vue'
import { DspInstruction, InstructionDsp } from '../../../../../../types/instruction_type'

export default defineComponent({
  name: 'BaseDSPOverwriting',
  components: { LabelLine },
  props: {
    props: {
      type: Object as PropType<{ item: DspInstruction }>,
      required: true
    },
    overwriteData: {
      type: Object as PropType<Record<InstructionDsp, DspOverwritingItem[]>>,
      required: true
    },
    cols: {
      type: String,
      required: false,
      default: '6'
    },
    mini: {
      type: Boolean,
      required: false,
      default: true
    },
    showPunctuationSpace: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    isOn (item: DspOverwritingItem) {
      if (item.trueValue) {
        return item.value === item.trueValue
      }
      return item.value
    },
    getTdClass () {
      return this.mini ? '' : 'wide'
    },
    getRowClass () {
      return this.mini ? 'mr-0' : 'my-1'
    }
  },
  computed: {
    overwrited: function () : DspOverwritingValue[] {
      let dsp: InstructionDsp = this.$route.params.dsp as InstructionDsp

      if (Object.keys(this.overwriteData).indexOf(dsp) === -1) {
        console.warn(`The dsp ${dsp} is not configured in overwriteData. No overwrite data will be displayed.`)
        return []
      }

      let filteredOverwrited = this.overwriteData[dsp].filter(item => {
        let defaultItem = this.$store.getters.getDefaultItem
        let toCheck = this.props.item[item.value] === undefined || this.props.item[item.value] === null ? false : this.props.item[item.value]
        return (!item.isAdvanced || (item.isAdvanced && toCheck !== defaultItem[item.value])) &&
          (item.displayRules === undefined || item.displayRules(this.props.item))
      })

      return filteredOverwrited.map(item => {
        let tooltip = false
        let isOrange = false
        if (typeof item.tooltip === 'function') {
          tooltip = item.tooltip(this.props)
        }
        if (typeof item.isOrange === 'function') {
          isOrange = item.isOrange(this.props)
        }

        const trueValue = item.trueValue ? item.trueValue : null

        if (this.props.item[item.value] === undefined || this.props.item[item.value] === null) {
          return {
            label: item.label,
            value: !!item.reverseBoolean,
            isAdvanced: item.isAdvanced,
            tooltip: tooltip,
            isOrange: isOrange,
            trueValue: trueValue
          } as DspOverwritingValue
        }
        return {
          label: item.label,
          value: item.reverseBoolean ? !this.props.item[item.value] : this.props.item[item.value],
          isAdvanced: item.isAdvanced,
          tooltip: tooltip,
          isOrange: isOrange,
          trueValue: trueValue
        } as DspOverwritingValue
      })
    }
  },
  watch: {

  }
})
