<template>
    <v-row no-gutters wrap>
      <v-col
        :cols="!long && !little ? 7 : (long ? 10 : 5)"
      >
        <LabelLine :mini="mini" :label="label"></LabelLine>
      </v-col>
      <v-col
        class="mx-0"
        :cols="!long && !little ? 5 : (long ? 2 : 7)"
        x-small
        :class="(chipStyle ? 'pr-0 chip-style ' + $route.params.dsp : 'pr-0') + (blueStyle ? ' blueStyle' : '')"
      >
        <span class="span-align-label"><slot></slot></span>
      </v-col>
    </v-row>
</template>

<script>
import LabelLine from './LabelLine'

export default {
  name: 'AlignLabel',
  props: {
    label: String,
    mini: {
      type: Boolean,
      default: true
    },
    long: {
      type: Boolean,
      default: false
    },
    little: {
      type: Boolean,
      default: false
    },
    chipStyle: {
      type: Boolean,
      default: true
    },
    blueStyle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LabelLine
  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
.chip-style {
  text-align: center;

  /* NEW STYLE  */
  border-radius: 3px;
  background: #FF8700;
  color: white;
  margin: 1px;
  align-self: center;
  padding: 0;
}

.chip-style.mediamath {
  background: #C51230;
}

.chip-style.thetradedesk {
  background: #199AD6;
}

.chip-style.appnexus {
  background: #FF8700;
}

.chip-style.dbm {
  background: #5BB974;
}

.chip-style.youtube {
   background: #FF0000;
}

.chip-style.beeswax {
  background: #17514C;
}

.chip-style.facebook {
  background: #1877F2;
}

.chip-style.meta {
  background: #0081FB;
}

.chip-style.kayzen {
  background: black;
}

.chip-style.googleads {
  background: #FABC04;
}

.chip-style.blueStyle {
    /* OLD STYLE  */
  border-radius: 45px;
  background: #3486D7;
}
@media only screen and (max-width: 1823px) {
  .span-align-label {
    font-size: 8px;
  }
}
@media only screen and (min-width: 1824px) {
  .span-align-label {
    font-size: 10px;
  }
}
</style>
